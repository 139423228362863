import { FETCH_SESSIONS, FETCH_SESSION, INVITE_TO_SESSION } from '@/store/actions.type';
import { SET_SESSIONS, SET_CURRENT_SESSION, SET_LAST_SESSION } from '@/store/mutations.type';
import ApiService from '@/common/api.service';

const state = {
  sessions: [],
  currentSession: null,
  lastSession: null,
};

const getters = {
  sessions: () => state.sessions,
  currentSession: () => state.currentSession,
  lastSession: () => state.lastSession,
};

const actions = {
  [FETCH_SESSION](context, sessionId) {
    context.commit(SET_CURRENT_SESSION, sessionId);
  },
  // eslint-disable-next-line no-unused-vars
  [FETCH_SESSIONS](context, params = {}) {
    const { organizationId } = context.rootGetters;
    return new Promise((resolve, reject) => {
      ApiService.query(`front/v1/training_sessions?organization_id=${organizationId}`, params)
        .then(({ data }) => {
          context.commit(SET_SESSIONS, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [INVITE_TO_SESSION](context, code) {
    return new Promise((resolve, reject) => {
      ApiService.query(`front/v1/invite_to_session?code=${code}`)
        .then(({ data }) => {
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
};

const mutations = {
  [SET_CURRENT_SESSION](states, id) {
    const { sessions } = states;

    sessions.every((session) => {
      if (session.id === id) {
        states.currentSession = session;
        return false;
      }
      return true;
    });
  },
  [SET_SESSIONS](states, { data }) {
    const { training_sessions: sessions, last_training_sessions_id: lastTrainingSessionId } = data;
    states.sessions = sessions;
    if (lastTrainingSessionId && lastTrainingSessionId !== -1) {
      const filteredSessions = sessions.filter((session) => session.id === lastTrainingSessionId);
      if (filteredSessions.length > 0) {
        [states.lastSession] = filteredSessions;
      }
    } else {
      states.lastSession = null;
    }
  },
  [SET_LAST_SESSION](states, id) {
    states.lastSession = id;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
