import { FETCH_COURSE, FETCH_COURSES } from '@/store/actions.type';
import { SET_COURSE, SET_COURSES } from '@/store/mutations.type';
import ApiService from '@/common/api.service';

const state = {
  courses: null,
  course: null,
  courseIndex: null,
  lastCourse: false,
};

const getters = {
  courseIndex: () => state.courseIndex,
  prevCourse: () => (state.courses[state.courseIndex - 1]
    ? state.courses[state.courseIndex - 1] : null),
  nextCourse: () => (state.courses[state.courseIndex + 1]
    ? state.courses[state.courseIndex + 1] : null),
  course: () => state.course,
  courses: () => state.courses,
  lastCourse: () => state.lastCourse,
  coursesChapter: () => {
    const chapters = [];

    state.courses.forEach((course) => {
      let index = course.chapterBasicOrder;

      if (index === null) {
        index = 0;
      }
      if (!chapters[index]) {
        chapters[index] = [];
      }
      chapters[index].push(course);
    });

    return chapters.filter((x) => x !== undefined);
  },
};

const actions = {
  [FETCH_COURSES](context, sessionId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`front/v1/courses?training_session=${sessionId}`)
        .then(({ data }) => {
          context.commit(SET_COURSES, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [FETCH_COURSE](context, courseId) {
    context.commit(SET_COURSE, courseId);
  },
};

const mutations = {
  [SET_COURSES](states, { data }) {
    const { courses, lastCourseId } = data;
    states.courses = courses;
    states.lastCourse = lastCourseId;
  },
  [SET_COURSE](states, courseId) {
    states.course = states.courses.find((courseObject) => courseObject.id === courseId);
    states.courseIndex = states.courses.indexOf(states.course);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
